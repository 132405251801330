html {
    scroll-behavior: smooth;
    scroll-margin-top: 75px;
}

.grid-logo a.link--yaku {
    font-size: 2rem;
}

.section-header.double {
    top: 7.2rem;
}

.fourbox-overlay {
    background: none!important;
}

.close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    width: 1.5em;
    height: 1.5em;
    opacity: 0.5;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

#team-carousel img {
    filter: grayscale(100%);
}

#subscribe-now {
    margin-top: 2em;
    z-index: 1040;
    position: sticky;
    top: min(25%,6em);
    background: none;
    /*height: 250rem;*/
}
#subscribe-now .inline-form {
    position: relative;
    display: inline-block;
    padding: 1.5em;
    border-radius: 2em 0;
    /*background-color: cornflowerblue;*/
    background-color: #5dbcd2;
    /*background-color: rgba(254, 65, 100, .9);*/
}
#subscribe-now .inline-form > *:nth-child(2) {
    margin-right: 1.5em;
    margin-left: .5em;
}

#subscribe-now .input {
    margin-left: 0;
    color: white;
    vertical-align: middle;
}

#subscribe-now .input label {
    color: white;
    font-size: 0.8em;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}

#subscribe-now .input input {
    color: white;
}

.intro-section-2 .logo-center {
    display: inline-block;
    background-image: url('../images/logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    /* Don't forget to add height and width,
        Without content, the div won't have any height and with */
    height: 20em;
    width: 50%;
}

.grid figcaption h3.long-name div {
    font-size: 75%;
    line-height: 1em;
}


/* small-only */
@media (max-width: 767px) {
    .section-header.double + .container .water-mark:first-child .main-title {
        margin-top: -25px;
        top: 25px;
    }

    .water-mark .main-title {
        overflow-x: hidden;
    }

    .boxgallery > nav span.next, .boxgallery > nav span.prev {
        width: 2.5em;
        height: 2.5em;
    }
    .intro-section-2 .logo-center {
        width: 80%;
    }
}

/* desktop */
@media (min-width: 768px) {
    .section-header.double {
        top: 5.5rem;
    }
}